.video-player {
  width: 90%;
  max-width: 1250px;
}

.controls-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
}

.controls-container p {
  text-align: left;
  width: 200px;
}

.button-container {
  display: flex;
  justify-content: left;
  gap: 1rem;
}

.button-container button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
}

.playback-rate {
  margin-left: 1rem;
}
