body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4000bf;
  font-family: 'open sans', sans-serif;
  font-size: 20px;
  color: #dfdfff;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:link,
a:visited {
  color: #dfdfff;
  text-decoration: none;
  padding: 10px 20px;
  display: inline-block;
}
a:hover,
a:active {
  background-color: #5000d7;
}

table {
  margin-left: auto;
  margin-right: auto;
}

table,
th,
td {
  text-align: center;
}

tr:hover {
  background-color: #9900bb;
}
