.error-banner {
  background-color: red;
  color: white;
  padding: 10px;
  position: relative;
}

.error-banner-close {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
}
