@import '../assets/css/shared.css';

.login-container {
  flex-direction: column;
  margin: auto;
  width: 250px;
  padding: 20px;
}

.login-box {
  display: flex;
  flex-direction: column;
  background-color: #000046;
  padding: 30px;
  width: 100%;
  border-radius: 10px;
  margin: auto auto 20px;
}

.login-box p {
  margin-top: 30px;
  text-align: left;
}

.login-field-container,
.password-field-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /*padding: 5px;*/
}

.login-field,
.password-field {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.login-button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}

.login-button:hover {
  background-color: #45a049;
}

.password-field-container {
  position: relative;
}

.password-field-container i {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-10px);
  cursor: pointer;
}
