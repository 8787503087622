.lazy-leven-header {
  background-color: #2964ba;
  width: 100%;
  padding: 10px 0;
}

.lazy-leven-header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lazy-leven-header ul .left-group {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.lazy-leven-header ul .left-group li {
  margin-right: 10px;
  position: relative; /* Added for dropdown positioning */
}

.lazy-leven-header ul li {
  display: inline-block;
  color: #ffffff;
}

.lazy-leven-header ul li a {
  color: #ffffff;
  text-decoration: none;
}

.logo-container {
  position: relative;
  width: 250px;
  height: 43px;
  display: flex;
}

.lazy-leven-sign-img {
  z-index: 2;
}

.lazy-leven-sign-img,
.lazy-leven-sign-img-hover {
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.lazy-leven-sign-img-hover {
  z-index: 1;
  position: absolute;
  padding-left: 65px;
  width: 120px;
  transform: translateY(0%);
}

.logo:hover .lazy-leven-sign-img-hover {
  transform: translateY(120%);
  opacity: 1;
}

/* Dropdown Menu Styles */
.dropdown {
  position: relative;
  display: inline-block;
  min-width: 160px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #2964ba;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  /*color: black;*/
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #5000d7;
}

.dropdown:hover .dropdown-content {
  display: block;
}
