.input-field {
}

.button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.button:hover {
  background-color: #45a049;
}

.alert {
  color: green;
  margin-top: 20px;
}
