.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.button {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #6193cb;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0.5rem;
}

.button:hover {
  color: #fff;
  background-color: #203651;
  border-color: #083562;
}

.button:focus,
.button:active {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
